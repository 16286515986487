<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-img id="logoImg" src="https://switchq.co.il/assets/img/logo.png" />
        <v-list-item-content>
          <v-list-item-title class="text-h4 mx-4" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list>
      <div v-for="(link, i) in computedItems" :key="i">
        <v-list-item
          v-if="!link.children"
          :key="i"
          :to="link.to"
          active-class="current-route"
          avatar
          class="v-list-item py-1"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-title v-text="link.title" />
        </v-list-item>

        <v-list-group
          v-else
          :key="link.title"
          no-action
          :value="link.children.find(l => l.active)"
        >
          <template v-slot:activator>
            <v-list-item class="ps-0 py-1">
              <v-list-item-action>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="sublink in link.children"
            :key="sublink.title"
            :to="sublink.to"
            active-class="not-current-route"
            :class="sublink.active ? 'current-route ps-9 py-1' : 'ps-9 py-1'"
          >
            <v-list-item-action>
              <v-icon>{{ sublink.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title v-text="sublink.title" />
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [],
  }),

  computed: {
    drawer: {
      get () {
        return this.$store.getters['drawer/drawer']
      },
      set (val) {
        this.$store.dispatch('drawer/setDrawer', val)
      },
    },
    barColor () {
      return this.$store.getters['drawer/barColor']
    },
    computedItems () {
      return this.items.map(this.mapItem)
    },
    profile () {
      return {
        avatar: true,
        title: this.$t('control-center'),
      }
    },
  },

  mounted () {
    this.setItems()
  },

  methods: {
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
        active: !!(this.$route.path == item.to),
      }
    },
    setItems () {
      const items = [
        {
          title: 'pages.dashboard',
          icon: 'mdi-view-dashboard',
          to: '/',
        },
        {
          title: 'pages.clients',
          icon: '$clientsGroup',
          children: [
            {
              title: 'all clients',
              icon: 'mdi-account-group',
              to: '/clients',
            },
            {
              title: 'new client',
              icon: 'mdi-account-plus',
              to: '/clients/create',
            },
          ],
        },
        {
          title: 'users',
          icon: 'mdi-account-group',
          children: [
            {
              title: 'all users',
              icon: 'mdi-account-group',
              to: '/users',
            },
            // {
            //   title: 'משתמשים מחוברים',
            //   icon: 'mdi-account-network',
            //   to: '/users/online',
            // },
            {
              title: 'new user',
              icon: 'mdi-account-plus',
              to: '/users/create',
            },
          ],
        },
        {
          title: 'devices',
          icon: '$devices',
          to: '/devices',
          // children: [
          //   {
          //     title: 'all the devices',
          //     icon: 'mdi-chart-bubble',
          //     to: '/components/icons',
          //   },
          //   {
          //     title: 'pages.onlinedevices',
          //     icon: 'mdi-chart-bubble',
          //     to: '/components/icons',
          //   },
          // ],
        },
        // {
        //   title: 'icons',
        //   icon: 'mdi-chart-bubble',
        //   to: '/components/icons',
        // },
        // {
        //   title: 'google',
        //   icon: 'mdi-map-marker',
        //   to: '/maps/google-maps',
        // },
        // {
        //   title: 'notifications',
        //   icon: 'mdi-bell',
        //   to: '/components/notifications',
        // },
        // {
        //   title: 'TEST',
        //   icon: 'mdi-account-off-outline',
        //   to: '/test',
        // },
        {
          title: 'signout',
          icon: 'mdi-account-off-outline',
          to: '/signout',
        },
      ]
      this.items = items.map(item => {
        item.title = this.$phraser.set(item.title)
        if (item.children) {
          item.children.map(childitem => {
            childitem.title = this.$phraser.set(childitem.title)
            return childitem
          })
        }
        return item
      })
    },
  },
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  #logoImg
    max-width: 50px

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
